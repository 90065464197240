import { Limit } from './models/antar-api-response.model';

export abstract class AntarConstants {
	public static readonly CB_CURVE_TYPES: { [key: string]: string } = { 'It': 'It', 'I2t': 'I2t', 'ItRcd': 'ItRcd' } as const;
	public static readonly CURVE_TYPES = [
		{ key: this.CB_CURVE_TYPES['It'], value: 'I-t', tooltip: 'Time-current curve' },
		{ key: this.CB_CURVE_TYPES['I2t'], value: 'I²t', tooltip: 'Let-through energy curve' },
		{ key: this.CB_CURVE_TYPES['ItRcd'], value: 'I-t RCD', tooltip: 'Residual Current Device Time-current curve' }
	];
	public static readonly BOOLEAN_STRING: { [key: string]: string } = { 'FALSE': 'false', 'TRUE': 'true' } as const;
	public static readonly CB_COLORS:string[] = [ 'black', 'red', 'green' ];
	public static readonly EXPORT_CHART_OPTIONS:string[] = [ 'PDF', 'JPEG' ];
	public static readonly CABLE_TYPE: string[] = [ 'phCable', 'nCable', 'peCable', 'penCable' ] ;
	public static readonly CURVE_NETWORK_PARAM:{ [key: string]: string } = { 'ib': 'black',
		'ik3Max': 'red',
		'ik1Max': 'orange',
		'ik1Min': 'blue',
		'iefMin': 'green',
		'Ph': 'crimson',
		'N': 'blue',
		'PE': 'yellow',
		'PEN': '#FFFF33',
		'phCable': 'crimson',
		'peCable': 'yellow',
		'nCable': 'blue',
		'penCable': '#FFFF33'
	};
	public static readonly CABLE_CURVE_TYPE = { 'Ph': 'crimson', 'N': 'blue', 'PE': 'yellow', 'PEN': '#FFFF33' };
	public static readonly CB_CURVE_COLORS:{ [key: string]: string } = { 'incomer': 'var(--main-color-series1)', 'outgoing': 'var(--main-color-series2)', 'Transformer': 'orange' };
	public static readonly SETTINGS_NAME:string[] = [ 'Thermal Fragments', 'Ir', 'tr', 'Isd', 'i2t', 'Tsd', 'iideactivated', 'Ii', 'Ib', 'IK3Max', 'Ik1Max', 'Ik1Min', 'IefMin', 'Iz', 'I∆n', '∆t' ];
	public static readonly ANTAR_LANGUAGES = [ 'en', 'fr', 'de' ];
	public static readonly SETTINGS_TAB_NAMES:{[key:string]:{[key:string]:boolean}} = {
		Trip: { current: false, trip: true },
		Current: { current: true, trip: false }
	};
	public static readonly USER_SETTING_PARAM:{ [key: string]: string } = { 'ib': 'Ib', 'ik3Max': 'IK3Max_(KA)', 'ik1Max': 'Ik1Max_(KA)', 'ik1Min': 'ik1Min_(KA)', 'iefMin': 'IefMin_(KA)' };
	public static readonly DEVICE_NETWORKLINES = [ 'ib', 'ik3Max', 'ik1Max', 'ik1Min', 'iefMin' ];
	public static readonly COMMON_NETWORKLINES = [ 'PhCableKValue', 'PhCableSValue', 'PECableKValue',
		'PECableSValue', 'NCableKValue', 'NCableSValue', 'PENCableKValue', 'PENCableSValue' ];
	public static readonly ANTAR_OPERATORS = [ 'GRTR', 'LSSR', 'GRTREQL', 'LSSREQL', 'EQL', 'NOTEQL' ];
	public static readonly ANTAR_ERROR_WARNING_ATTRIBUTE = [ 'ir' ];
	public static readonly LAUNCHSYSTEM = { 'CEL': 'CEL', 'ANTARES': 'ANTARES' };
	public static readonly TIME_CHART_SERIES = { 'START': 'START', 'END': 'END', 'LINE': 'LINE' };
	public static readonly EYEICON : string[] = [ 'LABELS', 'SECOND_AXIS' ];
	public static readonly SELECTIVITY_TYPE: { [key: string]: string } = { 'NO_SELECTIVITY': 'Noselectivity', 'TOTAL': 'Total', 'PARTIAL': 'Partial' };
	public static readonly LOADER_TYPE : { [key: string]: string } = { 'MAIN_LOADER': 'MAIN', 'INFORMATION_LOADER': 'INFORMATION' };
	public static readonly LABEL_TYPE : {[key:string]:string} = { CIRCUIT_BREAKER: 'Circuit Breaker', NETWORK: 'network', FUSE: 'Fuse', CABLE: 'cable', RCD: 'rcd', TRANSFORMER: 'Transformer' } as const;
	public static readonly TOOLBAR_TOOLS: {[key:string]:string} = { MOUSE_POINTER: 'mousePointer', PAN: 'Pan', ZOOM_IN: 'zoomIn', ZOOM_OUT: 'zoomOut', EXPORT: 'export', RESIZE: 'resize', COORDINATES: 'Coordinates', TIME_DIFFERENCE: 'timeDifference' } as const;
	public static readonly TOOLBAR_ICONS = [
		{
			'id': 'mousePointer',
			'tooltipName': 'POINTER'
		},
		{
			'id': 'Pan',
			'tooltipName': 'PAN'
		},
		{
			'id': 'zoomIn',
			'tooltipName': 'ZOOMIN'
		},
		{
			'id': 'zoomOut',
			'tooltipName': 'ZOOMOUT'
		},
		{
			'id': 'resize',
			'tooltipName': 'RESIZE'
		},
		{
			'id': 'export',
			'tooltipName': 'EXPORT'
		},
		{
			'id': 'Coordinates',
			'tooltipName': 'COORDINATES'
		},
		{
			'id': 'timeDifference',
			'tooltipName': 'TIMEDIFFERENCE'
		}
	];
	public static readonly CEL_CURVE_NAME : { [key: string]: string } = { 'Min': 'Min', 'Max': 'Max' };
	public static readonly CABLE_TYPE_KS: { [key: string]: string } = { 'K': 'k', 'S': 's', 'CoreMaterial': 'CoreMaterial', 'CoreInsulationType': 'CoreInsulationType', 'NoOfCable': 'NoOfCable', 'Name': 'Name', 'Type': 'Type' } as const;	public static readonly BREAKER_POSITIONING :{ [key: string]: string } = { 'incomer': 'incomer', 'outgoing': 'outgoing' };
	public static readonly RCD_SETTINGS_NAME = [ 'i∆n', '∆t' ];
	public static readonly POSITION: string[] = [ 'incomer', 'outgoing' ];
	public static readonly RCD_SETTINGS : { [key: string]: string } = { 'I∆n': 'i∆n', '∆t': '∆t' };
	public static readonly DEVICE_TYPE: { [key: string]: string } = { 'CircuitBreaker': 'Circuit Breaker', 'RCD': 'RCD', 'Fuse': 'Fuse' };
	public static readonly RCD_POINTS:{ [key: string]: number } = { 'LeftTop': 100, 'RightBottom': 1000 };
	public static readonly SETTING_TOOLTIP: { [key: string]: string } = { 'I∆n': 'Earth leakage pickup', '∆t': 'Earth leakage tripping delay' };
	public static readonly I2T_CURVE_LIMITS: { [key: string]: number } = { 'noInfinityMinY': -2, 'noInfinityMaxY': 9, 'noInfinityMinX': -4, 'noInfinityMaxX': 3 };
	public static readonly TIC_CURVE_LIMITS: Limit = { 'noInfinityMinY': -2, 'noInfinityMaxY': 5, 'noInfinityMinX': -2, 'noInfinityMaxX': 6 };
	public static readonly TIC_CURVE_T_VALUES:number[] = [ 0.01, 0.05, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 120, 150, 180, 200, 240, 300, 360, 420, 480, 540, 600, 700, 800, 1000 ];
	public static readonly TIC_PARAM: {[key: string]: string } = { 'Te': 'Te', 'Ne': 'Ne', 'TrIn': 'TrIn' };
}

