import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseDeviceData, DeviceInfo, Response, ResponseData, Point, DataPoints, Selectivity } from 'src/app/common/models/antar-api-response.model';
import { AntarConstants } from 'src/app/common/antar.constants';
import { RequestData } from 'src/app/common/models/antart-api-request.model';
import { TokenService } from 'src/app/core/services/token.service';
@Injectable( {
	providedIn: 'root'
} )

export class LoadDataService {
	apiUrl: string = '';
	antarCurveGenerationApiUrl = environment.apiUrl + 'GenerateTCCData';
	selectivityResultApiUrl = environment.apiUrl + 'ProtectionSelectivity/GetCurveSelectivityResult';
	multivendorApiUrl = environment.multivendorApiUrl;
	launchSystem = environment.launchSystem;
	commonResponse!:Response;
	selectivityResultByTableApiUrl = environment.apiUrl + 'ProtectionSelectivity/GetSelectivityByTable';
	constructor ( private http: HttpClient, private tokenService: TokenService ) {
		if ( this.launchSystem !== undefined )
		{
			console.log( 'environment.lunchsystem ', this.launchSystem );
			this.apiUrl = this.launchSystem == AntarConstants.LAUNCHSYSTEM.ANTARES ? this.antarCurveGenerationApiUrl : this.multivendorApiUrl;
		}
	}
	fetchGraphPoints ( curveData: RequestData ): Observable<ResponseData> {
		if ( this.launchSystem === AntarConstants.LAUNCHSYSTEM.CEL ) {
			return this.http.post<ResponseData>( this.multivendorApiUrl, curveData ).pipe(
				map( response => {
					response.tccDataResponse.curveDataPoints.forEach( curvedata => {
						if ( curvedata['settingsData'] === undefined ) {
							curvedata.settingsData = {
								deviceId: curvedata.deviceId,
								deviceType: 'Circuit Breaker',
								deviceLegendName: curvedata.deviceLegendName,
								legendDataList: curvedata.legendDataList !== null ? curvedata.legendDataList : null
							};
						}
					} );
					return response;
				} )
			);
		} else {
			const requestDataForApi:RequestData = {
				applicationName: curveData.applicationName,
				data: curveData.data.filter( item => item.protectionDeviceType != AntarConstants.LABEL_TYPE['TRANSFORMER'] ),
				networkParameters: curveData.networkParameters
			};
			return this.http.post<Response>( this.apiUrl, requestDataForApi ).pipe(
				map( response => response.data )
			);
		}
	}
	getSelectivityResult ( curvePointsData: ResponseDeviceData[] ): Observable<Selectivity> {
		const deviceData: DeviceInfo[] = curvePointsData.map( ( data:ResponseDeviceData ) => ( { deviceId: data.deviceId, breakerPositioning: data.breakerPositioning, points: this.launchSystem === AntarConstants.LAUNCHSYSTEM.ANTARES ? data.points : this.getCelDataPoints( data.dataPoints ) } ) );
		return this.http.post<Response>( this.selectivityResultApiUrl, { 'curveDataContent': deviceData } )
			.pipe( map( response => response.data ) );
	}
	getSelectivityResultByTable ( cbData: RequestData ): Observable<Response> {
		const requestDataForApi:RequestData = {
			applicationName: cbData.applicationName,
			data: cbData.data.filter( item => item.protectionDeviceType != AntarConstants.LABEL_TYPE['TRANSFORMER'] ),
			networkParameters: cbData.networkParameters
		};
		return this.http.post<Response>( this.selectivityResultByTableApiUrl, requestDataForApi ).pipe(
			map( response => response )
		);
	}
	getCelDataPoints ( datapoints?:DataPoints[] ):Point[] {
		let combinedPoints:Point[] = [];
		datapoints?.forEach( data => {
			if ( data.points )
				combinedPoints = [ ...data.points ];
		} );
		return combinedPoints;
	}
}

